/* ==========================================================================
   #SPACINGS
   ========================================================================== */

/**
 * Utility classes to put specific spacing values onto elements. The below loop
 * will generate us a suite of classes like:
 *
 *   .u-margin-top {}
 *   .u-padding-left-large {}
 *   .u-margin-right-small {}
 *   .u-padding {}
 *   .u-padding-right-none {}
 *   .u-padding-horizontal {}
 *   .u-padding-vertical-small {}
 */

/* stylelint-disable string-quotes */

/**
 * loop over margin & padding
 */
@each $property-namespace, $property in $spacing-properties {

  /**
   * loop over directions
   */
  @each $direction-namespace, $direction-rules in $spacing-directions {

    .u-#{$property-namespace}#{$direction-namespace}-none {
      @each $direction in $direction-rules {
        #{$property}#{$direction}: 0 !important;
      }
    }

    /**
     * loop over sizes (tiny, small, large etc)
     */
    @each $size-name, $size in $spacing-unit-sizes {

      /**
       * e.g. u-padding-left-large
       */

      $size-namespace: '';

      @if ($size-name != null) {
        $size-namespace: '-#{$size-name}';
      }

      .u-#{$property-namespace}#{$direction-namespace}#{$size-namespace} {

        @each $direction in $direction-rules {
          @if ($size-name != null) {
            #{$property}#{$direction}: var(--spacing-unit-#{$size-name}) !important;
          }
          @else {
            #{$property}#{$direction}: var(--spacing-unit) !important;
          }
        }

      }

    }

  }

}
