/* ==========================================================================
   #FEED-ITEM
   ========================================================================== */


.c-feed-item {
  display: flex;
  // border-top: 1px solid sem-color(border);
  @include theme-transition(border-color);
  scroll-margin-top: var(--site-header-height);
  position: relative;
  z-index: 0;
}

  .c-feed-item__background {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: calc(#{sp()} * -1);
    right: calc(#{sp()} * -1);
    transition: background-color $hover-transition;
    @include theme-transition(background-color);
    border-radius: var(--rounded-radius);

    .c-feed-item--active > & {
      background-color: var(--state-color-active);
    }
  }

// .c-feed-item__button {}


  .c-feed-item__description {

    p {
      @include ms-respond(font-size, .75);
      line-height: var(--body-2-line-height);
      --body-2-line-height: 1.4em;

      @include mq($from: 20em, $until: 60em) {
        --body-2-line-height: #{ms-fluid(1.4em, 1.45em, 20em, 60em)};
      }

      @include mq($from: 60em) {
        --body-2-line-height: 1.45em;
      }

      margin-bottom: sp(small);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }


  .c-feed-item__meta {
    display: flex;
  }

    .c-feed-item__bullet-separator {
      margin-left: sp(micro);
      margin-right: sp(micro);
      white-space: nowrap;
    }
