/* ==========================================================================
   #WRAPPER
   ========================================================================== */

.o-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: calc(#{rem(720px)} + var(--site-margin) * 2);
  padding-left: var(--site-margin);
  padding-right: var(--site-margin);
}

.o-wrapper\@sm {
  @include mq($from: sm) {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(#{rem(720px)} + var(--site-margin) * 2);
    padding-left: var(--site-margin);
    padding-right: var(--site-margin);
  }
}

.o-wrapper-l {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--site-margin);
  padding-right: var(--site-margin);
  max-width: calc(var(--container-width) + var(--site-margin) * 2);
}

.o-wrapper-toolbar {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--spacing-unit);
  padding-right: var(--spacing-unit);
  max-width: calc(var(--container-width) + var(--spacing-unit) * 2);
}

.o-wrapper-xl {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--site-margin);
  padding-right: var(--site-margin);
  max-width: calc(var(--container-width-xl) + var(--site-margin) * 2);
}

.o-wrapper-full {
  padding-left: var(--site-margin);
  padding-right: var(--site-margin);
}
