@mixin layout-vars {
  --container-width:      #{rem(960px)};
  --container-width-xl:   #{rem(1200px)};
  --site-margin:          #{sp()};
  --player-height:        #{101px};
  --site-header-height:   #{70px};

  @include mq($from: sm) {
    --site-margin:          #{sp(large)};
  }
}
