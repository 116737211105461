/* ==========================================================================
   #PARAGRAPHS
   ========================================================================== */

p {
  word-break: break-word;
  @include ms-respond(font-size, 1);
  line-height: 1.5em;

  @include mq($from: 20em, $until: 60em) {
    line-height: ms-fluid(1.5em, 1.55em, 20em, 60em);
  }

  @include mq($from: 60em) {
    line-height: 1.55em;
  }
}
