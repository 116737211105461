/* ==========================================================================
   #PLAYBACK-BAR
   ========================================================================== */


.c-playback-bar {
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  grid-gap: sp(small);
  align-items: center;
}

  .c-playback-bar__progress-time {
    text-align: center;
    @include ms-respond(font-size, 0);
    color: sem-color(text-2);
    @include theme-transition(color);
    line-height: 1;
  }

  .c-playback-bar__progress-time--disabled {
    text-align: center;
    color: sem-color(text-disabled);
  }

