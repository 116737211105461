/* ==========================================================================
   #SLIDER
   ========================================================================== */


@keyframes mdc-slider-emphasize {
  0% {
    animation-timing-function: ease-out;
  }
  50% {
    animation-timing-function: ease-in;
    transform: scale(0.9);
  }
  100% {
    transform: scale(0.667);
  }
}

.mdc-slider {
  position: relative;
  width: 100%;
  height: 30px;
  cursor: pointer;
  touch-action: pan-x;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mdc-slider:not(.mdc-slider--disabled) .mdc-slider__track {
  background-color: #26C6DA;
}
.mdc-slider:not(.mdc-slider--disabled) .mdc-slider__track-container::after {
  background-color: #26C6DA;
  opacity: 0.26;
}
.mdc-slider:not(.mdc-slider--disabled) .mdc-slider__track-marker-container {
  background-color: #26C6DA;
}
.mdc-slider:not(.mdc-slider--disabled) .mdc-slider__thumb {
  fill: #26C6DA;
  stroke: #26C6DA;
}
.mdc-slider:not(.mdc-slider--disabled) .mdc-slider__focus-ring {
  background-color: #26C6DA;
}

.mdc-slider--disabled {
  cursor: auto;
}
.mdc-slider--disabled .mdc-slider__track {
  background-color: #9a9a9a;
}
.mdc-slider--disabled .mdc-slider__track-container::after {
  background-color: #9a9a9a;
  opacity: 0.26;
}
.mdc-slider--disabled .mdc-slider__track-marker-container {
  background-color: #9a9a9a;
}
.mdc-slider--disabled .mdc-slider__thumb {
  fill: #9a9a9a;
  stroke: #9a9a9a;
}
.mdc-slider--disabled .mdc-slider__thumb {
  stroke: sem-color(surface);
  @include theme-transition(stroke);
}
.mdc-slider:focus {
  outline: none;
}
.mdc-slider__track-container {
  position: absolute;
  top: calc(50% - 1px);
  width: 100%;
  height: 3px;
  border-radius: 1px;
  overflow: hidden;
}
.mdc-slider__track-container::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
}
.mdc-slider__track {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: left top;
  will-change: transform;
}
.mdc-slider[dir=rtl] .mdc-slider__track, [dir=rtl] .mdc-slider .mdc-slider__track {
  transform-origin: right top;
}

.mdc-slider__track-marker-container {
  display: flex;
  margin-right: 0;
  margin-left: -1px;
  visibility: hidden;
}
.mdc-slider[dir=rtl] .mdc-slider__track-marker-container, [dir=rtl] .mdc-slider .mdc-slider__track-marker-container {
  margin-right: -1px;
  margin-left: 0;
}

.mdc-slider__track-marker-container::after {
  display: block;
  width: 2px;
  height: 2px;
  content: "";
}
.mdc-slider__track-marker {
  flex: 1;
}
.mdc-slider__track-marker::after {
  display: block;
  width: 2px;
  height: 2px;
  content: "";
}
.mdc-slider__track-marker:first-child::after {
  width: 3px;
}
.mdc-slider__thumb-container {
  position: absolute;
  top: 5px;
  left: 0;
  width: 21px;
  height: 100%;
  user-select: none;
  will-change: transform;
}
.mdc-slider__thumb {
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0.667);
  stroke-width: 3.5;
  transition:
    transform 100ms ease-out,
    fill 100ms ease-out,
    stroke 100ms ease-out;
}
.mdc-slider__focus-ring {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 266.67ms ease-out, background-color 266.67ms ease-out, -webkit-transform 266.67ms ease-out;
  transition: transform 266.67ms ease-out, opacity 266.67ms ease-out, background-color 266.67ms ease-out;
  transition: transform 266.67ms ease-out, opacity 266.67ms ease-out, background-color 266.67ms ease-out, -webkit-transform 266.67ms ease-out;
}
.mdc-slider__pin {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-top: -2px;
  margin-left: -2px;
  transform: rotate(-45deg) scale(0) translate(0, 0);
  border-radius: 50% 50% 50% 0%;
  z-index: 1;
  transition: transform 100ms ease-out;
}

.mdc-slider--active .mdc-slider__thumb {
  transform: scale3d(1, 1, 1);
}

.mdc-slider--focus .mdc-slider__thumb {
  animation: mdc-slider-emphasize 266.67ms linear;
}
.mdc-slider--focus .mdc-slider__focus-ring {
  transform: scale3d(1.55, 1.55, 1.55);
  opacity: 0.25;
}

.mdc-slider--in-transit .mdc-slider__thumb {
  transition-delay: 140ms;
}

.mdc-slider--in-transit .mdc-slider__thumb-container,
.mdc-slider--in-transit .mdc-slider__track,
.mdc-slider:focus:not(.mdc-slider--active) .mdc-slider__thumb-container,
.mdc-slider:focus:not(.mdc-slider--active) .mdc-slider__track {
  transition: transform 80ms ease;
}

.mdc-slider--discrete.mdc-slider--active .mdc-slider__thumb {
  transform: scale(calc(12 / 21));
}
.mdc-slider--discrete.mdc-slider--active .mdc-slider__pin {
  transform: rotate(-45deg) scale(1) translate(19px, -20px);
}
.mdc-slider--discrete.mdc-slider--focus .mdc-slider__thumb {
  animation: none;
}
.mdc-slider--discrete.mdc-slider--display-markers .mdc-slider__track-marker-container {
  visibility: visible;
}
