/* ==========================================================================
   #PODCAST-IMAGE
   ========================================================================== */

/**
 * Image with background colour before it loads
 * and an inset border to ensure it doesn't blend in to the background.
 */

.c-podcast-image {
  --podcast-image-radius: var(--rounded-radius);
  background-color: sem-color(background-code);
  border-radius: var(--podcast-image-radius);
  overflow: hidden;
  @include theme-transition(background-color);

  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--podcast-image-radius);
    box-shadow: 0 0 0 1px sem-color(text, .12) inset;
  }
}

  .c-podcast-image--small {
    --podcast-image-radius: 2px;
  }
