/* ==========================================================================
   #PODCAST-HEADER
   ========================================================================== */

.c-podcast-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: sp();

  @include mq($from: xs) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: sp(large);
  }

  @include mq($from: sm) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include mq($from: lg) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.c-podcast-header__content {
  grid-column: 2 / -1;
}
