/* ==========================================================================
   #VOLUME-SLIDER
   ========================================================================== */

.c-volume-slider {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 230px;
}
