/* ==========================================================================
   #ARTICLE
   ========================================================================== */


.s-article a {
  @include focused-link;

  &:hover {
    color: sem-color(link);
  }
}
