/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */

:root {
  box-sizing: border-box;
}

* {

  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }

}
