@mixin light-color-vars {
  --color-primary:             #{rgb-material("cyan", "600")};
  --color-background:          #{rgb-triplet("white")};
  // --color-background:          #{rgb-triplet("white", $darken: 2%)};
  --color-background-code:     #{rgb-triplet("white", $darken: 6%)};
  --color-surface:             #{rgb-triplet("white")};
  --color-playbar:             #{rgb-triplet("white")};
  --color-input-border:        #{rgb-triplet("blue-grey-50")};
  --color-text:                #{rgb-triplet("blue-grey-900")};
  --color-text-2:              #{rgb-triplet("blue-grey-500")};
  --color-text-3:              #{rgb-triplet("blue-grey-400")};
  --color-text-4:              #{rgb-triplet("blue-grey-300")};
  --color-text-disabled:       #{rgb-triplet("blue-grey-100")};
  --color-disabled:            #{rgb-triplet("blue-grey-200")};
  --color-quote-border:        #{rgb-triplet("blue-grey-400")};
  --color-border:              #{rgb-triplet("blue-grey-50")};
  --color-selection:           #{rgb-material("cyan", "400")};
  --color-selection-inactive:  #{rgb-triplet("blue-grey-300")};
  --color-link:                #{rgb-material("cyan", "400")};
  --color-button:              #{rgb-material("teal", "400")};
  --color-twitter-profile:     #{rgb-triplet("blue-grey-600")};

  --color-code-red:            #{rgb-material("red", "500")};
  --color-code-pink:           #{rgb-material("pink", "800")};
  --color-code-purple:         #{rgb-material("purple", "400")};
  --color-code-deep-purple:    #{rgb-material("deep-purple", "600")};
  --color-code-indigo:         #{rgb-material("indigo", "800")};
  --color-code-blue:           #{rgb-material("blue", "800")};
  --color-code-light-blue:     #{rgb-material("light-blue", "800")};
  --color-code-cyan:           #{rgb-material("cyan", "600")};
  --color-code-teal:           #{rgb-material("teal", "800")};
  --color-code-green:          #{rgb-material("green", "800")};
  --color-code-light-green:    #{rgb-material("light-green", "800")};
  --color-code-lime:           #{rgb-material("lime", "800")};
  --color-code-yellow:         #{rgb-material("yellow", "800")};
  --color-code-amber:          #{rgb-material("amber", "800")};
  --color-code-orange:         #{rgb-material("orange", "700")};
  --color-code-deep-orange:    #{rgb-material("deep-orange", "500")};
  --color-code-brown:          #{rgb-material("brown", "800")};
  --color-code-blue-grey:      #{rgb-material("blue-grey", "500")};
  --color-code-dark-blue-grey: #{rgb-material("blue-grey", "700")};

  --mdc-theme-secondary:       #{material-color("cyan", "600")};
}

@mixin dark-color-vars {
  --color-primary:             #{rgb-material("cyan", "400")};
  --color-background:          #{rgb-triplet("blue-grey-950")};
  --color-background-code:     #{rgb-triplet("blue-grey-900")};
  --color-surface:             #{rgb-triplet("blue-grey-900")};
  --color-playbar:             #{rgb-triplet("blue-grey-850")};
  --color-input-border:        #{rgb-triplet("blue-grey-850")};
  --color-text:                #{rgb-triplet("white")};
  --color-text-2:              #{rgb-triplet("blue-grey-200")};
  --color-text-3:              #{rgb-triplet("blue-grey-300")};
  --color-text-4:              #{rgb-triplet("blue-grey-400")};
  --color-text-disabled:       #{rgb-triplet("blue-grey-800")};
  --color-disabled:            #{rgb-triplet("blue-grey-600")};
  --color-quote-border:        #{rgb-triplet("blue-grey-300")};
  --color-border:              #{rgb-triplet("blue-grey-850")};
  --color-selection:           #{rgb-material("cyan", "300")};
  --color-selection-inactive:  #{rgb-triplet("blue-grey-300")};
  --color-link:                #{rgb-material("cyan", "300")};
  --color-button:              #{rgb-material("teal", "300")};
  --color-twitter-profile:     #{rgb-triplet("blue-grey-700")};

  --color-code-red:            #{rgb-material("red", "200")};
  --color-code-pink:           #{rgb-material("pink", "200")};
  --color-code-purple:         #{rgb-material("purple", "200")};
  --color-code-deep-purple:    #{rgb-material("deep-purple", "200")};
  --color-code-indigo:         #{rgb-material("indigo", "200")};
  --color-code-blue:           #{rgb-material("blue", "200")};
  --color-code-light-blue:     #{rgb-material("light-blue", "200")};
  --color-code-cyan:           #{rgb-material("cyan", "200")};
  --color-code-teal:           #{rgb-material("teal", "200")};
  --color-code-green:          #{rgb-material("green", "200")};
  --color-code-light-green:    #{rgb-material("light-green", "200")};
  --color-code-lime:           #{rgb-material("lime", "200")};
  --color-code-yellow:         #{rgb-material("yellow", "200")};
  --color-code-amber:          #{rgb-material("amber", "200")};
  --color-code-orange:         #{rgb-material("orange", "200")};
  --color-code-deep-orange:    #{rgb-material("deep-orange", "200")};
  --color-code-brown:          #{rgb-material("brown", "200")};
  --color-code-blue-grey:      #{rgb-material("blue-grey", "200")};
  --color-code-dark-blue-grey: #{rgb-material("blue-grey", "50")};

  --mdc-theme-secondary:       #{material-color("cyan", "400")};
}
