/* ==========================================================================
   #RATIO
   ========================================================================== */

// A list of aspect ratios that get generated as modifier classes.

$ratios: (
  (1:1),
  (3:2),
  (16:9),
) !default;



/**
 * Create ratio-bound content blocks, to keep media (e.g. images, videos) in
 * their correct aspect ratios.
 *
 * http://alistapart.com/article/creating-intrinsic-ratios-for-video
 *
 * 1. Default cropping is a 1:1 ratio (i.e. a perfect square).
 */

.o-ratio {
  position: relative;
  display: block;

  &::before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 100%; /* [1] */
    padding-bottom: calc(var(--img-height) / var(--img-width) * 100%);
  }

}


  .o-ratio__content,
  .o-ratio > iframe,
  .o-ratio > embed,
  .o-ratio > object {
    position: absolute;
    top:    0;
    bottom: 0;
    left:   0;
    height: 100%;
    width:  100%;
  }



/* stylelint-disable */

/* Ratio variants.
   ========================================================================== */

/**
 * Generate a series of ratio classes to be used like so:
 *
 *   <div class="o-ratio  o-ratio--16:9">
 *
 */

@each $ratio in $ratios {

  @each $antecedent, $consequent in $ratio {

    @if (type-of($antecedent) != number) {
      @error "`#{$antecedent}` needs to be a number."
    }

    @if (type-of($consequent) != number) {
      @error "`#{$consequent}` needs to be a number."
    }

    .o-ratio--#{$antecedent}\:#{$consequent}::before {
      padding-bottom: ($consequent/$antecedent) * 100%;
    }

  }

}

/* stylelint-enable */
