@mixin spacing-vars {
  --spacing-unit-xxmicro: 3px;

  @each $size-name, $size in $spacing-unit-sizes {
    @if ($size-name != null) {
      @include ms-respond(--spacing-unit-#{$size-name}, $size, $spacing-scale);
    }
    @else {
      @include ms-respond(--spacing-unit, $size, $spacing-scale);
    }
  }

}
