/* ==========================================================================
   #LISTS
   ========================================================================== */

ul,
ol {
  margin-left: sp(large);
}

li {
  @include ms-respond(font-size, 1);
  margin-bottom: sp(micro);

  > ul,
  > ol {
    margin-bottom: 0;
  }
}
