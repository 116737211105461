/* ==========================================================================
   #HOME-VIEW
   ========================================================================== */

.c-home-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-rss-url-listbox {
  position: relative;
  top: -90px;
  width: 100%;
}

.c-rss-image {
  width: 5.125rem;
  height: 5.125rem;
}

.c-rss-a {
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: auto 1fr;
  text-decoration: none;
  margin: 0.5rem 0px;
  padding: 0.75rem 1rem;
  cursor: pointer;
}
