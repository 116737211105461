/* ==========================================================================
   #ICONS
   ========================================================================== */

.c-icon {
  width: 40px;
  height: 40px;
  display: block;

  path {
    fill: sem-color(text);
    @include theme-transition(fill);
  }
}
